import React from 'react'
import ReactDOM from 'react-dom'
import { getAttributes } from './modules/util'

import './scss/styles.scss'

if (document.querySelectorAll('[data-pulldown]') !== null) {
	import('./components/forms/pulldown').then(({default: Pulldown}) => {
		const selectors = document.querySelectorAll('[data-pulldown]')
		selectors.forEach(selector => {
			const props = getAttributes(selector.attributes)
			ReactDOM.render(<Pulldown {...props} />, selector)
		})
	}).catch(e => {
		console.log(e)
	})
}

if (document.querySelectorAll('[data-datepicker]') !== null) {
	import('./components/forms/datepicker').then(({default: Datepicker}) => {
		const selectors = document.querySelectorAll('[data-datepicker]')
		selectors.forEach(selector => {
			const props = getAttributes(selector.attributes)
			ReactDOM.render(<Datepicker {...props} />, selector)
		})
	}).catch(e => {
		console.log(e)
	})
}

if (document.querySelectorAll('[data-editable]') !== null) {
	import('./components/forms/editable').then(({default: Editable}) => {
		const selectors = document.querySelectorAll('[data-editable]')
		selectors.forEach(selector => {
			const props = getAttributes(selector.attributes)
			ReactDOM.render(<Editable {...props} />, selector)
		})
	}).catch(e => {
		console.log(e)
	})
}
